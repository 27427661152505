<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 65vw; min-height: 80vh;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ $t('Debug') }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section>
        <q-splitter
          v-model="splitterModel"
          style="height: 100%;"
        >
          <template v-slot:before>
            <q-tabs
              v-model="currentTab"
              vertical
              class="text-teal"
            >
              <q-tab
                name="selectedData"
                :label="$t('Selected Data')"
              />

              <q-tab
                name="requests"
                :label="$t('Requests')"
              />
            </q-tabs>
          </template>

          <template v-slot:after>
            <q-tab-panels
              v-model="currentTab"
              animated
              swipeable
              vertical
              transition-prev="jump-up"
              transition-next="jump-up"
            >
              <q-tab-panel name="selectedData">
                <div v-if="data">
                  <q-tabs v-model="dataTab">
                    <q-tab
                      name="raw"
                      :label="$t('RAW')"
                    />

                    <q-tab
                      v-if="data.curl"
                      name="curl"
                      :label="$t('cURL')"
                    />

                    <q-tab
                      name="json"
                      :label="$t('JSON')"
                    />
                  </q-tabs>

                  <q-separator />

                  <q-tab-panels v-model="dataTab" animated>
                    <q-tab-panel name="raw">
                      <object-code :data="raw" />
                    </q-tab-panel>

                    <q-tab-panel name="curl" v-if="data.curl">
                      <object-code :data="data.curl" />
                    </q-tab-panel>

                    <q-tab-panel name="json">
                      <object-code :data="data" />
                    </q-tab-panel>
                  </q-tab-panels>
                </div>
              </q-tab-panel>

              <q-tab-panel name="requests">
                <div class="text-h4 q-mb-md">
                  {{ $t('Requests') }}
                </div>

                <q-list
                  bordered
                  separator
                >
                  <q-item
                    v-for="(data, index) in events"
                    :key="index"
                    v-ripple
                    clickable
                    @click="handleClick(data)"
                  >
                    <q-item-section>
                      {{ data.url }}
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-tab-panel>
            </q-tab-panels>
          </template>
        </q-splitter>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ObjectCode from '../history-collapse/ObjectCode.vue'

// Utils
import { doCopy } from '../../utils/copy-utils'

export default {
  name: 'DebugModal',
  components: { ObjectCode },
  data () {
    return {
      dataTab: 'raw',
      isOpen: false,
      data: null,
      currentTab: 'selectedData',
      splitterModel: 20
    }
  },
  computed: {
    ...mapGetters([
      'localNotifications',
      'isOpenDebugModal',
      'debugData'
    ]),
    raw () {
      if (!this.data) {
        return ''
      }

      let value = `${this.data.method} ${this.data.fullURL} \n ${Object.entries(this.data.headers).map(([key, value]) => `${key}: ${value}`).join('\n')} \n`

      const saveMethods = ['POST', 'PATCH', 'PUT']

      if (saveMethods.includes(this.data.method)) {
        value += this.data.body
      }

      return value
    },
    events () {
      return this.localNotifications.map(value => value.data)
    }
  },
  watch: {
    isOpen (value) {
      if (!value) {
        this.closeDebugModal()
      }
    },
    isOpenDebugModal (value) {
      if (!value) {
        return
      }

      this.isOpen = value
      this.data = this.debugData
    }
  },
  methods: {
    ...mapMutations([
      'closeDebugModal'
    ]),
    handleCopy () {
      doCopy(JSON.stringify(this.data))
    },
    handleClick (data) {
      this.currentTab = 'selectedData'
      this.data = null

      setTimeout(() => {
        this.data = data
      })
    },
    open (data) {
      this.isOpen = true
      this.data = data
    },
    close () {
      this.isOpen = false
      this.closeDebugModal()
    }
  }
}
</script>
